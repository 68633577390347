import React, {FunctionComponent} from "react";
import styled from 'styled-components';

type VideoProps = {
  embedCode: string;
}

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0; 
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video: FunctionComponent<VideoProps> = ({embedCode}) => {
  return <VideoWrapper>
    <iframe width="560" height="315" src={`https://www.youtube.com/embed/${embedCode}`} title="YouTube video player"
            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
  </VideoWrapper>;
}

export default Video;
import React, {FunctionComponent, useState} from "react";
import Member from "../../domain/member";
import styled from 'styled-components';
import Avatar from "../avatar/avatar";
import {CondensedSubHeader, SubTitle} from "../typography/typography";
import Email from '../icons/email';
import LinkIcon from '../icons/link';
import Phone from "../icons/phone";

type MemberProps = {
  member: Member;
}

const MemberContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
  width: 100%;
  gap: 8px;
`;

const PhoneLink = styled.a`
  color: white;
  background-color: ${({ theme }) => theme.dark_signature};
  padding: 4px 8px;
  border-radius: 20px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: white;
    text-decoration: none;
    background-color: ${({ theme }) => theme.signature};
  }
`;

const Member: FunctionComponent<MemberProps> = ({member}) => {
  const [showIcon, setShowIcon] = useState<boolean>(true);
  const {image, name, company, email, website, phone, showPhoneNumber} = member;

  return <MemberContainer>
    <Avatar url={image}/>

    {(email || website || phone) && <EmailContainer>
      {website && <a href={website} target={'_blank'} rel={'nofollow'}><LinkIcon/></a>}
      {email && <a href={`mailto:${email}`}><Email/></a>}
      {showPhoneNumber === undefined || showPhoneNumber && phone && <>
        {showIcon && <span onClick={() => setShowIcon(false)}><Phone/></span>}
        {!showIcon && <PhoneLink href={`tel:${phone}`} type={'tel'}>{phone}</PhoneLink>}
      </>}
    </EmailContainer>}
    <SubTitle style={{marginTop: '20px'}}>{name}</SubTitle>
    <CondensedSubHeader style={{textAlign: 'center'}}>{company}</CondensedSubHeader>
  </MemberContainer>;
};

export default Member;
import React, {FunctionComponent, useContext} from 'react';
import Layout from './components/layout/layout';
import Landing from "./pages/landing";
import Network from './pages/network';
import Contact from './pages/contact';
import Events from './pages/events';
import Members from "./pages/members";
import Magazines from "./pages/magazines";
import Article from "./pages/article";
import Magazine from "./components/magazines/magazine";
import {ContentItem, ContentType} from "./domain/content-response";
import {useLocation} from "react-router-dom";
import {ContentContext, ContentContextState} from "./app";
import PageProps from "./domain/page-props";
import MetaData from './components/meta/meta-data';

const contentTypeMap: Record<ContentType, FunctionComponent<PageProps<ContentItem>>> = {
  landing: Landing,
  network: Network,
  contact: Contact,
  events: Events,
  members: Members,
  magazines: Magazines,
  magazineArticles: Magazine,
  global: Landing,
  article: Article
}

const findContent = (path: string, labels: Record<string, ContentItem>): ContentItem => {
  if (path === '/') {
    const content = Object.values(labels);
    return content.find((c) => c.contentTypeAlias === "landing");
  }

  return labels[`${location.pathname}/`.replace('//', '/')];
}

const View: FunctionComponent = () => {
  const location = useLocation();
  const {labels} = useContext<ContentContextState>(ContentContext);
  const content = findContent(location.pathname, labels);

  if (!content) return null;
  const Component = contentTypeMap[content.contentTypeAlias];

  return <>
    <MetaData metaData={content} />

    <Layout>
      <Component content={content}/>
    </Layout>
  </>;
};

export default View;


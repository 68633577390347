import React, {FunctionComponent} from "react";
import styled from 'styled-components';

type TextInputProps = {
  value: string;
  type?: 'text' | 'email' | 'tel';
  onChange: (value: string) => void;
  placeholder: string;
}

const Input = styled.input`
  border: none;
  border-bottom: 1px solid ${({theme}) => theme.signature};
  padding: 12px 0;
  background: transparent;
  width: 100%;
  
  &:focus {
      outline: none;
  }
  
  margin-top: 30px;
`;

const TextInput: FunctionComponent<TextInputProps> = ({type = 'text', value, placeholder, onChange}) => {
  return <Input type={type} value={value} placeholder={placeholder} onChange={(event) => onChange(event.target.value)} />
};

export default TextInput;
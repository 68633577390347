import styled, {css} from 'styled-components';
import {Link} from "react-router-dom";

export const Button = styled.button`
  
`;

const SecondaryButtonStyles = css`
  color: ${({ theme }) => theme.grey};
  font-family: 'Inconsolata', monospace;
  border-radius: 32px;
  background: white;
  border: 2px solid ${({ theme }) => theme.counter_signature};
  padding: 12px 30px;
`;

export const SecondaryButton = styled.button`
  ${SecondaryButtonStyles}
`;

export const SecondaryButtonLink = styled(Link)`
  ${SecondaryButtonStyles}  
  
  &:hover {
    color: ${({ theme }) => theme.grey};
    text-decoration: none;
  }
`;
import React, {FunctionComponent} from "react";
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 100px;
`;

const PageContainer: FunctionComponent = ({children}) =>
  <Container className={'container'}>{children}</Container>;

export default PageContainer;
import React, {FunctionComponent, useContext} from "react";
import _ from 'lodash';
import {ImageMedia, NetworkContent} from "../domain/content-response";
import PageProps from "../domain/page-props";
import {Bold, SubHeader} from "../components/typography/typography";
import Avatar from '../components/avatar/avatar';
import PageContainer from "../components/layout/page-container";
import Video from '../components/video/video';
import AlternateSegment from "../components/layout/alternate-segment";
import {SecondaryButtonLink} from "../components/button/button";
import {ContentContext, ContentContextState} from "../app";
import Member from "../components/member/member";
import ClickableImage from "../components/image/clickable-image";
import styled from "styled-components";

const Image = styled(ClickableImage)`
  margin-bottom: 40px;
`;

const RightCol = styled.div`
  text-align: center;
  
  @media all and (min-width: 968px) {
    text-align: right;
  }
`;

const MemberColumn = styled.div`
  text-align: center;
`;

const Network: FunctionComponent<PageProps<NetworkContent>> = ({content}) => {
  const {
    networkTitle,
    networkDescription,
    networkImage,
    elaboratedTitle,
    elaboratedDescription,
    elaboratedDescriptionVideo,
    imagesHeadline,
    images,
    membersHeadline,
    membersDescription,
    membersLink,
    membersLinkText,
    networkAPIKey
  } = content;

  const firstHalf = [];
  const secondHalf = [];

  images.forEach((image, index) => {
    if (index % 2 === 0)
      firstHalf.push(image);
    else
      secondHalf.push(image);
  });

  const {members} = useContext<ContentContextState>(ContentContext);
  const networkMembers = members[networkAPIKey] && _.take(members[networkAPIKey], 4);

  return <>
    <PageContainer>
      <div className={'row'}>
        <div className="col-md-6 animate__animated animate__fadeIn">
          <SubHeader>{networkTitle}</SubHeader>
          <div className={'animate__animated animate__fadeIn'}
               dangerouslySetInnerHTML={{__html: networkDescription}}/>
        </div>
        <RightCol className="col-md-6 animate__animated animate__fadeIn">
          {networkImage?._url && <Avatar url={networkImage._url}/>}
        </RightCol>
      </div>
      <div className="row mt-5">
        <div className="col-md-12 animate__animated animate__fadeIn">
          <Bold>{elaboratedTitle}</Bold>
          <div dangerouslySetInnerHTML={{__html: elaboratedDescription}}/>
          {elaboratedDescriptionVideo && <Video embedCode={elaboratedDescriptionVideo}/>}
        </div>
      </div>
    </PageContainer>
    {images && <AlternateSegment className={"animate__animated animate__fadeIn animate__delay-1s"}>
      <Bold>{imagesHeadline}</Bold>
      <div className="row">
        <div className="col-md-6">
          {firstHalf?.map((image) => <Image key={image._url} src={image._url} />)}
        </div>
        <div className="col-md-6">
          {secondHalf?.map((image) => <Image key={image._url} src={image._url} />)}
        </div>
      </div>
    </AlternateSegment>}
    <PageContainer>
      <Bold>{membersHeadline}</Bold>
      <div dangerouslySetInnerHTML={{__html: membersDescription}} />

      <div className="row" style={{marginTop: '20px'}}>
        {networkMembers && networkMembers.map((member) => <MemberColumn key={member.email} className={'col-md-3'}>
          <Member member={member} />
        </MemberColumn>)}
      </div>

      <div className={'text-right mt-5'}>
        <SecondaryButtonLink to={membersLink?._url}>{membersLinkText}</SecondaryButtonLink>
      </div>
    </PageContainer>

  </>
};

export default Network;
import React, {FunctionComponent, useContext} from "react";
import styled from 'styled-components';
import {StyledLink, SubHeader} from "../typography/typography";
import {ContentContext, ContentContextState} from "../../app";
import Dots from "../icons/dots";

const FooterContainer = styled.div`
  background-color: ${({theme}) => theme.dark_grey};
  color: ${({theme}) => theme.white};
  padding: 16px 30px 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FooterSubHeader = styled(SubHeader)`
  color: ${({theme}) => theme.white};
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

const DotsContainer = styled.div`
  display: inline;
  margin: 0 32px;
`;


const Footer: FunctionComponent = () => {
  const {networkNavigation, topLevelNavigation} = useContext<ContentContextState>(ContentContext);
  return <FooterContainer>
    <div className="d-none d-lg-inline-block">
      {networkNavigation.map((item) => (<React.Fragment key={item.url}>
        <StyledLink to={item.url}>{item.text}</StyledLink>
        |
      </React.Fragment>))}

      {topLevelNavigation.map((item, index) => (<React.Fragment key={`${item.url}_${index}`}>
        <StyledLink style={{marginRight: index === topLevelNavigation.length - 1 ? '0' : '16px'}}
                    to={item.url}>{item.text}</StyledLink>
        {index < topLevelNavigation.length - 1 && '|'}
      </React.Fragment>))}

      <DotsContainer>
        <Dots/>
      </DotsContainer>
    </div>

    <FooterSubHeader>Netværk i Øjenhøjde</FooterSubHeader>
  </FooterContainer>

};

export default Footer;
import React, {FunctionComponent, useContext} from "react";
import styled from 'styled-components';
import {ContentContext, ContentContextState} from "../../app";
import {ContentContainer, SubHeader} from "../typography/typography";
import {GlobalContent} from "../../domain/content-response";
import {SecondaryButtonLink} from "../button/button";

const ContactFooterContainer = styled.div`
  height: 800px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContactFooterBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
`;

const ContactFooterSubHeader = styled(SubHeader)`
  color: ${({ theme }) => theme.grey};
  margin-bottom: 12px;
`;

const ContactFooter: FunctionComponent = () => {
  const {content} = useContext<ContentContextState>(ContentContext);
  const global = content.find((c) => c.contentTypeAlias === 'global') as GlobalContent;

  if (!global) return null;

  const {contactHeadline, contactText, contactButton, contactLink} = global;

  return <ContactFooterContainer>
    <ContactFooterBackground style={{backgroundImage: `url("/footer-bg.png")`}} />

    <div className={'container-fluid'}>
      <div className="col-md-3 offset-md-2 text-center">
        <ContactFooterSubHeader>{contactHeadline}</ContactFooterSubHeader>
        <ContentContainer style={{padding: '16px 0 32px'}} dangerouslySetInnerHTML={{__html: contactText}} />

        <div style={{textAlign: 'center'}}>
          <SecondaryButtonLink to={contactLink._url}>{contactButton}</SecondaryButtonLink>
        </div>

      </div>
    </div>

  </ContactFooterContainer>

};

export default ContactFooter;
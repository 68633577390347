import React from "react";

function Dots() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="10"
      viewBox="0 0 59 12"
    >
      <g fill="#d43b8e" data-name="Group 18" transform="translate(-1390 -27)">
        <circle
          cx="6"
          cy="6"
          r="6"
          data-name="Ellipse 13"
          transform="translate(1390 27)"
        ></circle>
        <circle
          cx="6"
          cy="6"
          r="6"
          data-name="Ellipse 14"
          transform="translate(1414 27)"
        ></circle>
        <ellipse
          cx="5.5"
          cy="6"
          data-name="Ellipse 15"
          rx="5.5"
          ry="6"
          transform="translate(1438 27)"
        ></ellipse>
      </g>
    </svg>
  );
}

export default Dots;

import React, {FunctionComponent} from "react";
import {SubHeader} from "../components/typography/typography";
import PageProps from "../domain/page-props";
import {MagazineContent} from "../domain/content-response";
import PageContainer from "../components/layout/page-container";
import MagazineBrief from "../components/magazines/magazine-brief";

const Magazines: FunctionComponent<PageProps<MagazineContent>> = ({content}) => {
  const {magazineHeadline, magazineDescription, briefs} = content;

  return <>
    <PageContainer>
      <div className={'row'}>
        <div className="col-md-12">
          <SubHeader className={'animate__animated animate__fadeIn'}>{magazineHeadline}</SubHeader>
          <div className={'animate__animated animate__fadeIn'}
               dangerouslySetInnerHTML={{__html: magazineDescription}}/>
        </div>
      </div>
    </PageContainer>
    {briefs.map((brief, index) =>
      <MagazineBrief key={index} brief={brief}/>)}
  </>;
}

export default Magazines;
import React, {FunctionComponent} from "react";
import {ContactContent} from "../domain/content-response";
import PageProps from "../domain/page-props";
import styled from 'styled-components';
import {SubHeader} from "../components/typography/typography";
import LargePlane from "../components/icons/large-plane";

const ReceiptContainer = styled.div`
  display: flex;
  height: 60vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Received: FunctionComponent<PageProps<ContactContent>> = ({content}) => {
  const {receiptHeadline, receiptCaption} = content;

  return <ReceiptContainer className={'container'}>
    <SubHeader>
      {receiptHeadline}
    </SubHeader>
    <div className={'animate__animated animate__fadeIn text-center mb-5'}
         dangerouslySetInnerHTML={{__html: receiptCaption}}/>
    <LargePlane/>
  </ReceiptContainer>

};

export default Received;
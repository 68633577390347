import React, {FunctionComponent, useState} from "react";
import {Bold, SubHeader} from "../components/typography/typography";
import PageContainer from "../components/layout/page-container";
import PageProps from "../domain/page-props";
import {ContactContent} from "../domain/content-response";
import styled from 'styled-components';
import TextInput from "../components/input/text-input";
import {SecondaryButton} from "../components/button/button";
import Plane from "../components/icons/plane";
import {validateEmail} from "../components/validation";
import Received from "./received";
import axios from 'axios';
import {BASE_APP} from "../app";

const ContactContainer = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.light_grey};
  padding: 40px;
`;

const PositionedPlane = styled(Plane)`
  position: absolute;
  transform: translateY(-100%);
  right: 40px;  
`;

const Contact: FunctionComponent<PageProps<ContactContent>> = ({content}) => {
  const {
    contactHeadline,
    contactDescription,
    formHeadline,
    formNamePlaceholder,
    formBusinessPlaceholder,
    formMailPlaceholder,
    formPhonePlaceholder,
    formButtonLabel
  } = content;
  const [name, setName] = useState<string>('');
  const [business, setBusiness] = useState<string>('');
  const [email, setMail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [received, setReceived] = useState<boolean>(false);

  const send = () => {
    const errors = [];
    if (!name) {
      errors.push('Indtast venligst dit navn.');
    }

    if (!email || !validateEmail(email)) {
      errors.push('Den indtastede email adresse er ikke gyldig.');
    }

    if (errors.length > 0) {
      setErrors(errors);
      setTimeout(() => setErrors([]), 2500);
      return;
    }

    setLoading(true);
    axios.post(`${BASE_APP}/api/post-message`, {
      name,
      business,
      email,
      phone
    })
      .then(() => {
        setReceived(true);
      })
      .catch((e) => {
        setErrors(['Der skete en fejl ved afsending af din henvendelse.']);
        setTimeout(() => setErrors([]), 2500);
      });
  }

  if (received) return <Received content={content} />

  return <PageContainer>
    <div className="row">
      <div className="col-md-6 animate__animated animate__fadeIn">
        <SubHeader>{contactHeadline}</SubHeader>
        <div className={'animate__animated animate__fadeIn'}
             dangerouslySetInnerHTML={{__html: contactDescription}}/>
      </div>
    </div>

    <div className="row mt-5 animate__animated animate__fadeIn animate__delay-1s">
      <div className="col-md-6 offset-md-3">
        <ContactContainer>
          <PositionedPlane />
          <Bold className={'mb-1'} style={{fontSize: '16px'}}>{formHeadline}</Bold>
          <TextInput value={name} onChange={setName} placeholder={formNamePlaceholder}/>
          <TextInput value={business} onChange={setBusiness} placeholder={formBusinessPlaceholder}/>
          <TextInput type={'email'} value={email} onChange={setMail} placeholder={formMailPlaceholder} />
          <TextInput type={'tel'} value={phone} onChange={setPhone} placeholder={formPhonePlaceholder} />
        </ContactContainer>

        {errors.length > 0 && errors.map((e, index) => <p  key={index}>{e}</p>) }
        {loading && <p>Sender...</p>}

        <div className={'text-right mt-5'}>
          <SecondaryButton disabled={errors.length > 0} onClick={send}>{formButtonLabel}</SecondaryButton>
        </div>
      </div>
    </div>
  </PageContainer>;
}

export default Contact;
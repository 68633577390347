import React, {FunctionComponent, useContext} from "react";
import {SubHeader} from "../components/typography/typography";
import PageContainer from "../components/layout/page-container";
import PageProps from "../domain/page-props";
import {MemberContent, NetworkContent} from "../domain/content-response";
import {ContentContext, ContentContextState} from "../app";
import Member from "../components/member/member";
import _ from 'lodash';

const Members: FunctionComponent<PageProps<MemberContent>> = ({content}) => {
  const {membersHeadline, membersDescription, parentId} = content;
  const {members, labels} = useContext<ContentContextState>(ContentContext);
  const items = Object.values(labels);
  const networkItem = items.find((item) => item._id === parentId) as NetworkContent;
  const networkMembers = networkItem && members[networkItem.networkAPIKey] || [];
  const sortedMembers = _.sortBy(networkMembers, (user) => user.name);

  return <PageContainer>
    <div className="row">
      <div className="col-md-6 animate__animated animate__fadeIn">
        <SubHeader>{membersHeadline}</SubHeader>
        <div className={'animate__animated animate__fadeIn'}
             dangerouslySetInnerHTML={{__html: membersDescription}}/>
      </div>
    </div>

    <div className="row mt-5">
      {sortedMembers.map((member) => <div key={member.id} className={'col-md-3 text-center'}>
        <Member member={member} />
      </div>)}
    </div>

  </PageContainer>;
}

export default Members;
import React, {FunctionComponent, useState} from "react";
import styled from 'styled-components';
import Overlay from "../overlay/overlay";

type ClickableImageProps = {
  src: string;
  className?: string;
  fullHeight?: boolean;
}

const Img = styled.img<{ fullHeight?: boolean }>`
  height: ${({ fullHeight }) => fullHeight ? '100%' : 'auto'};
  width: 100%;
  cursor: pointer;
`;

const LargeImage = styled.img`
  position: fixed;
  width: 75vw;
  z-index: 200;
`;

const ClickableImage: FunctionComponent<ClickableImageProps> = ({src, className, fullHeight}) => {
  const [showLargeImage, setShowLargeImage] = useState<boolean>(false);

  return <>
    {showLargeImage && <Overlay onClick={() => setShowLargeImage(false)}>
      <LargeImage src={src} />
    </Overlay>}

    <Img fullHeight={fullHeight} className={className} src={src} onClick={() => setShowLargeImage(true)} />
  </>;
};

export default ClickableImage;
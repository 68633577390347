import React, {FunctionComponent} from "react";
import PageProps from "../domain/page-props";
import {ArticleContent} from "../domain/content-response";
import PageContainer from "../components/layout/page-container";
import {SubHeader, StyledLink, StyledNativeLink} from "../components/typography/typography";
import styled from 'styled-components';
import ClickableImage from "../components/image/clickable-image";

const Img = styled.img`
  max-width: 200px;
`;

const Article: FunctionComponent<PageProps<ArticleContent>> = ({content}) => {
  const {articleTitle, website, address, logo, zipCodeCity, articleMedia} = content;

  return <PageContainer>
    <div className="row mb-5 animate__animated animate__fadeIn">
      <div className="col-md-6">
        <SubHeader>{articleTitle}</SubHeader>
        <p style={{margin: '0'}}>
          {address} <br/>
          {zipCodeCity}
        </p>
        {website && <StyledNativeLink target={'_blank'} rel={"nofollow"} href={website}>{website}</StyledNativeLink>}
      </div>

      <div className="col-md-6 text-right">
        {logo && <Img src={logo._url} />}
      </div>
    </div>

    <div className="row mt-5">
      <div className="col">
        <p><i>Klik for stort eller højreklik for at åbne på en ny fane.</i></p>
      </div>

      <ClickableImage fullHeight src={articleMedia._url} />
    </div>
  </PageContainer>;
};

export default Article;